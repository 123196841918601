<template>
    <Dialog 
        v-model:visible="dialogState"
        :closable="false"
        :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
        :style="{ width: '450px' }"
        :modal="true"
        class="p-fluid"
    >
        <template #header>
            <p class="nice-font"><i class="fi fi-rr-text"></i><span class="pl-1 mt-1">Bezeichnung ändern</span></p>
        </template>
        <div @keydown.esc="hideDialog" @keydown.enter="saveDialog()">
            <Panel>
                <div class="grid p-fluid">
                    <div class="col-12 pl-5 pr-5">
                        <span class="p-float-label mr-2">
                            <InputText id="periodName" ref="periodName" v-model="newName" locale="de-DE" class="nice-font inputtext-param text-center text-2xl" />
                            <label for="periodName" class="mr-2">Bezeichnung</label>
                        </span>
                    </div>
                </div>
            </Panel>
        </div>
        <template #footer>
        <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog()" />
        <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveDialog()" />
        </template>
    </Dialog>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'dialogChangeName',
    setup() {},
    emits: ['entrySave', 'entryAbort'],
    props: {
        showDialog: {
            type: Boolean,
            required: true,
        },
        nameToChange: {
            type: String,
            required: true,
        },
    },
    mounted() {
        this.newName = this.nameToChange;
        this.dialogState = this.showDialog
    },
    computed: {
        entryDialog: function () {
            return this.dialogState;
        },
    },
    data() {
        return {
            dialogState: false,
            newName: null,
        };
    },
    methods: {
        hideDialog() {
            this.dialogState = false;
            this.$emit('entryAbort');
        },
        resetDialogData() {
            this.newName = null;
        },
        saveDialog() {
            if (this.newName !== null && this.newName.length > 0) {
                this.$emit('entrySave', this.newName);
                // this.entryDialog = false;
                this.resetDialogData();
            }
        },
    },
});
</script>
